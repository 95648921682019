<template>
  <div>
    <v-container fluid>
      <page-title title="Custom Components" subtitle="Showcase of our custom components" />
      <v-divider class="my-6"></v-divider>
      <v-row>
        <v-col cols="12" md="4">
          <v-card elevation="2">
            <v-card-title>Slideout</v-card-title>
            <v-card-text>
              <h5>Preview</h5>
              <counter v-model="counter" @input="counterInput" :min="0" :max="200" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-divider class="my-6"></v-divider>
      <v-row>
        <v-col cols="12" md="4">
          <v-card elevation="2">
            <v-card-title> Slideout </v-card-title>
            <v-card-text>
              <h5>Preview</h5>
              <v-btn color="info" @click="slideouts.parent.active = true">
                Open SlideOut <i class="far fa-arrow-from-left ml-2 fa-flip-horizontal"></i>
              </v-btn>
              <slideout
                dock="right"
                size="640px"
                :visible.sync="slideouts.parent.active"
                :closeOnMaskClick="false"
              >
                <template v-slot:header>
                  <h3 class="font-weight-bold pa-1" small>
                    <i class="fad fa-plus-square mr-2"></i>Parent Title
                  </h3>
                  <v-btn @click="slideouts.parent.active = false" icon>
                    <i class="fal fa-times"></i>
                  </v-btn>
                </template>
                <v-container>
                  <h1 class="mt-2 mb-3">"PARENT" SLIDEOUT CONTENT</h1>
                  <v-btn @click="slideouts.child.active = true">
                    Open Child SlideOut
                    <i class="far fa-arrow-from-left ml-2 fa-flip-horizontal"></i>
                  </v-btn>
                  <slideout
                    dock="right"
                    size="87%"
                    :visible.sync="slideouts.child.active"
                    :append-to="null"
                    :style="''"
                    :closeOnMaskClick="false"
                  >
                    <template v-slot:header>
                      <h3 class="font-weight-bold pa-1" small>
                        <i class="fad fa-plus-square mr-2"></i>Child Title
                      </h3>
                      <v-btn @click="slideouts.child.active = false" icon>
                        <i class="fal fa-times"></i>
                      </v-btn>
                    </template>
                    <v-container>
                      <h1 class="mt-2">"CHILD" SLIDEOUT CONTENT</h1>
                    </v-container>
                    <template v-slot:footer>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="slideouts.child.active = false">
                          <i class="fal fa-times mr-2"></i>Close Child
                        </v-btn>
                      </v-card-actions>
                    </template>
                  </slideout>
                </v-container>
                <template v-slot:footer>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="slideouts.parent.active = false">
                      <i class="fal fa-times mr-2"></i>Close Parent
                    </v-btn>
                  </v-card-actions>
                </template>
              </slideout>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-divider class="my-6"></v-divider>
      <v-row>
        <v-col cols="12" md="4">
          <v-card elevation="2">
            <v-card-title>
              User Avatar
              <code class="ml-2 text-caption">userObj: {avatarUrl: null}</code>
            </v-card-title>
            <v-card-subtitle>
              <h5>Code</h5>
              <kbd>
                <code>&lt;user-avatar :user=&quot;userObj&quot;&gt;&lt;/user-avatar&gt;</code>
              </kbd>
            </v-card-subtitle>
            <v-card-text>
              <h5>Preview</h5>
              <user-avatar :user="userObj"></user-avatar>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card elevation="2">
            <v-card-title>
              User Avatar
              <code class="ml-2 text-caption">userObj: {avatarUrl: 'url-to-image'}</code>
            </v-card-title>
            <v-card-subtitle>
              <h5>Code</h5>
              <kbd>
                <code>&lt;user-avatar :user=&quot;userObj&quot;&gt;&lt;/user-avatar&gt;</code>
              </kbd>
            </v-card-subtitle>
            <v-card-text>
              <h5>Preview</h5>
              <user-avatar :user="userObj2"></user-avatar>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card elevation="2">
            <v-card-title>
              User Avatar <code class="ml-2 text-caption">[Props => <b>icon</b>]</code>
            </v-card-title>
            <v-card-subtitle>
              <h5>Code</h5>
              <kbd>
                <code>&lt;user-avatar :user=&quot;userObj&quot; icon&gt;&lt;/user-avatar&gt;</code>
              </kbd>
            </v-card-subtitle>
            <v-card-text>
              <h5>Preview</h5>
              <user-avatar :user="userObj" icon></user-avatar>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-divider class="my-6"></v-divider>
      <v-row>
        <v-col cols="12" md="4">
          <v-card elevation="2">
            <v-card-title> Single Image Uploader </v-card-title>
            <v-card-subtitle>
              <h5>Code</h5>
              <kbd class="d-flex">
                <code>
                  &lt;single-image-uploader id=&quot;assets-uploader&quot;
                  :apiUrl=&quot;imageApiUrl&quot;
                  @imageUpdated=&quot;onImageUpdated&quot;&gt;&lt;/single-image-uploader&gt;
                </code>
              </kbd>
            </v-card-subtitle>
            <v-card-text>
              <h5>Preview</h5>
              <p>imageUrl: "{{ imageUrl }}"</p>
              <single-image-uploader
                id="assets-uploader"
                :apiUrl="imageApiUrl"
                v-model="imageUrl"
              ></single-image-uploader>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-divider class="my-6"></v-divider>
      <v-row>
        <v-col cols="12" md="4">
          <v-card elevation="2" class="mb-3">
            <v-card-title>Project Card</v-card-title>
            <v-card-subtitle>
              <h5>Code</h5>
              <kbd class="d-flex">
                <code>
                  &lt;project-card :project=&quot;project&quot;&gt;&lt;/project-card&gt;
                </code>
              </kbd>
            </v-card-subtitle>
            <v-card-text>
              <h5>Preview</h5>
            </v-card-text>
          </v-card>
          <project-card :project="project"></project-card>
        </v-col>
      </v-row>
      <v-divider class="my-6"></v-divider>
      <v-row>
        <v-col cols="12" md="4">
          <v-card elevation="2">
            <v-card-title> Breadcrumbs </v-card-title>
            <v-card-subtitle>
              <h5>Input</h5>
              <code>
                items: [ { text: "DNA", disabled: false, to: { name: "dashboard", path: "/dashboard"
                } }, { text: "Assets", disabled: false, to: { name: "assets", path: "/assets" } } ]
              </code>
              <h5>Code</h5>
              <kbd class="d-flex">
                <code>
                  &lt;v-breadcrumbs-alt id=&quot;assets-breadcrumbs&quot;
                  :items=&quot;items&quot;&gt;&lt;/v-breadcrumbs-alt&gt;;
                </code>
              </kbd>
            </v-card-subtitle>
            <v-card-text>
              <h5>Preview</h5>
              <v-breadcrumbs-alt id="assets-breadcrumbs" :items="items"></v-breadcrumbs-alt>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-divider class="my-6"></v-divider>
      <v-row>
        <v-col cols="12" md="3">
          <v-card elevation="2">
            <v-card-title> Space Count </v-card-title>
            <v-card-subtitle>
              <h5>Code</h5>
              <kbd class="d-flex">
                <code>&lt;space-count count=&quot;21&quot; /&gt;</code>
              </kbd>
            </v-card-subtitle>
            <v-card-text>
              <h5>Preview</h5>
              <space-count :count="21" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card elevation="2">
            <v-card-title>
              Space Count <code class="ml-2 text-caption">[Props => <b>transparent</b>]</code>
            </v-card-title>
            <v-card-subtitle>
              <h5>Code</h5>
              <kbd class="d-flex">
                <code> &lt;space-count count=&quot;21&quot; transparent /&gt; </code>
              </kbd>
            </v-card-subtitle>
            <v-card-text>
              <h5>Preview</h5>
              <space-count :count="21" transparent></space-count>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card elevation="2">
            <v-card-title> Equipment Count </v-card-title>
            <v-card-subtitle>
              <h5>Code</h5>
              <kbd class="d-flex">
                <code>&lt;equipment-count count=&quot;21&quot; /&gt;</code>
              </kbd>
            </v-card-subtitle>
            <v-card-text>
              <h5>Preview</h5>
              <equipment-count :count="345" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card elevation="2">
            <v-card-title>
              Equipment Count <code class="ml-2 text-caption">[Props => <b>transparent</b>]</code>
            </v-card-title>
            <v-card-subtitle>
              <h5>Code</h5>
              <kbd class="d-flex">
                <code> &lt;equipment-count count=&quot;21&quot; transparent /&gt; </code>
              </kbd>
            </v-card-subtitle>
            <v-card-text>
              <h5>Preview</h5>
              <equipment-count :count="345" transparent />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-divider class="my-6"></v-divider>
      <v-row>
        <v-col cols="12" md="4">
          <v-card elevation="2">
            <v-card-title>
              Profile Card
              <code class="ml-2 text-caption">userObj: {avatarUrl: null}</code>
            </v-card-title>
            <v-card-subtitle>
              <h5>Code</h5>
              <kbd>
                <code
                  >&lt;user-profile-card
                  :user=&quot;userObj&quot;&gt;&lt;/user-profile-card&gt;</code
                >
              </kbd>
            </v-card-subtitle>
            <v-card-text>
              <h5>Preview</h5>
              <user-profile-card :user="userObj"></user-profile-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-divider class="my-6"></v-divider>
      <v-row>
        <v-col cols="12" lg="3" md="4">
          <v-card elevation="2">
            <v-card-title>
              Dater <code class="ml-2 text-caption">dateObj: Date.now() //current year</code>
            </v-card-title>
            <v-card-subtitle>
              <h5>Code</h5>
              <kbd>
                <code>&lt;dater :date=&quot;dateObj&quot;&gt;&lt;/dater&gt;</code>
              </kbd>
            </v-card-subtitle>
            <v-card-text>
              <h5>Preview</h5>
              <dater :date="dateObj"></dater>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3" md="4">
          <v-card elevation="2">
            <v-card-title>
              Dater <code class="ml-2 text-caption">dateObj => other years</code>
            </v-card-title>
            <v-card-subtitle>
              <h5>Code</h5>
              <kbd>
                <code>&lt;dater :date=&quot;dateObj&quot;&gt;&lt;/dater&gt;</code>
              </kbd>
            </v-card-subtitle>
            <v-card-text>
              <h5>Preview</h5>
              <dater :date="dateObj2"></dater>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3" md="4">
          <v-card elevation="2">
            <v-card-title>
              Dater <code class="ml-2 text-caption">[Props => <b>dateonly</b>] //current year</code>
            </v-card-title>
            <v-card-subtitle>
              <h5>Code</h5>
              <kbd>
                <code>&lt;dater :date=&quot;dateObj&quot; dateonly&gt;&lt;/dater&gt;</code>
              </kbd>
            </v-card-subtitle>
            <v-card-text>
              <h5>Preview</h5>
              <dater :date="dateObj" dateonly></dater>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3" md="4">
          <v-card elevation="2">
            <v-card-title>
              Dater <code class="ml-2 text-caption">[Props => <b>dateonly</b>] //other years</code>
            </v-card-title>
            <v-card-subtitle>
              <h5>Code</h5>
              <kbd>
                <code>&lt;dater :date=&quot;dateObj&quot; dateonly&gt;&lt;/dater&gt;</code>
              </kbd>
            </v-card-subtitle>
            <v-card-text>
              <h5>Preview</h5>
              <dater :date="dateObj2" dateonly></dater>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Slideout from "@hyjiacan/vue-slideout";
import "@hyjiacan/vue-slideout/lib/slideout.css";
export default {
  data() {
    return {
      counter: 5,
      project: {
        id: "asdasdadsa",
        name: "South district main building",
        createDate: "2014-08-12T19:33:09.6221727+02:00",
        client: {
          name: "Meta",
          logoUrl: "http://127.0.0.1:10000/devstoreaccount1/clients/meta-(2)_18-31-04.png",
        },
        address: "asdasdadsa",
        description: "asdasdadsa",
        imageUrl: "asdasdadsa",
        spaceCount: 20,
        equipmentCount: 325,
        total: 1363967.8,
      },
      userObj: {
        id: "db922f5e-e9ce-4a2b-8af3-3cb5a7",
        firstName: "Jerry",
        lastName: "Seinfeld",
        avatarUrl: null,
        roles: [
          {
            id: "1",
            name: "Administrator",
          },
          {
            id: "2",
            name: "Collaborator",
          },
          {
            id: "2",
            name: "Guest",
          },
          {
            id: "2",
            name: "Content Creator",
          },
        ],
      },
      userObj2: {
        id: "db922f5e-e9ce-4a2b-8af3-3cb5a7",
        firstName: "Jerry",
        lastName: "Seinfeld",
        avatarUrl:
          "https://www.bosshunting.com.au/wp-content/uploads/2020/11/Jerry-Seinfeld-Net-Worth.jpg",
      },
      dateObj: Date.now(),
      dateObj2: new Date(2021, 4, 25, 16, 47, 5, 1),
      imageUrl:
        "https://www.bosshunting.com.au/wp-content/uploads/2020/11/Jerry-Seinfeld-Net-Worth.jpg",
      imageApiUrl: `clients/Image`,
      items: [
        {
          text: "DNA",
          disabled: false,
          to: { name: "dashboard", path: "/dashboard" },
        },
        {
          text: "Assets",
          disabled: false,
          to: { name: "assets", path: "/assets" },
        },
      ],
      slideouts: {
        parent: {
          active: false,
          isLoading: false,
        },
        child: {
          active: false,
          isLoading: false,
        },
      },
    };
  },
  methods: {
    counterInput(val) {
      this.$log("<<<<<<<<< counterInput", this.counter);
    },
  },
  components: { Slideout },
};
</script>

<style lang="scss"></style>
